import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"

import style from './style/header.module.scss'

import { displayMenuItem, smartNav } from '../functions'


/**
 * Menu item
 * @typedef {Object} MenuItem
 * @property {string} name - Display name
 * @property {string} url - Url
 * @property {boolean} extern - External link
 * @property {string} className - html class
 * @proterty {MenuItem[]} children - Nested menu
 */


/**
 * Navbar items - desktop
 * @type {MenuItem[]}
 */
const menuItems = [{
	name: 'Company',
	url: '/company',
},{
	name: 'Services',
	url: '/services',
},{
	name: 'Vision',
	url: '/manifesto',
},{
	name: 'Q&A',
	url: '/Q-A',
},{
	name: 'Contact',
	url: '/contact',
	className: 'button button--navbar',
},]


/**
 * Navbar items - mobile
 * @type {MenuItem[]}
 */
const mobileItems = [{
	name: 'Company',
	url: '/company',
},{
	name: 'Career',
	url: '/career',
},{
	name: 'Services',
	url: '/services',
},{
	name: 'Press kit',
	url: '/',
	extern: true,
},{
	name: 'Vision',
	url: '/manifesto',
},{
	name: 'Q&A',
	url: '/Q-A',
},{
	name: 'Contact us',
	url: '/contact',
},];


/**
 * Social icons
 * @type {MenuItem[]}
 */

const socials = [{
	url: 'https://www.facebook.com/algamafoods/',
	className: style.facebook,
	extern: true,
},{
	url: 'https://www.instagram.com/algamafoods/',
	className: style.instagram,
	extern: true,
},{
	url: 'https://www.linkedin.com/company/algama/',
	className: style.linkedin,
	extern: true,
},{
	url: 'https://medium.com/@Algamafoods',
	className: style.medium,
	extern: true,
},{
	url: 'https://twitter.com/algamafoods',
	className: style.twitter,
	extern: true,
},{
	url: 'https://www.youtube.com/channel/UCchhsRckqTprIuGRWaQPHiQ',
	className: style.youtube,
	extern: true,
},];

/**
 * Toggle mobile navbar
 * @param  {boolean} options.open 			[description]
 * @param  {function} options.openMenu 	[description]
 * @return {function} 									[description]
 */
function toggleMenu({open, openMenu}) {
	return (evt) => {
		evt.preventDefault();
		openMenu(!open);
	}
}

// DOM site header
const header = React.createRef();

/**
 * Website header
 * @param  {String} options.siteTitle Site title
 */
const Header = ({ siteTitle }) => {
	const [ open, openMenu ] = useState(false);

	useEffect(() => {
	  let nav = new smartNav({
	    header: header.current,
	    navbarHeight: 80
	  });
	  nav.init();
	  return () => { nav.destroy(); }
	}, []);

	// Toggle menu class
	useEffect(() => {
		if ( open ) {
			document.body.classList.add(style.MenuOpen);
			document.body.classList.add('menu-open');
		}
		return () => {
			document.body.classList.remove(style.MenuOpen);
			document.body.classList.remove('menu-open');
		}
	}, [open]);

	return (
		<header 
			ref = { header }
			className = { style.siteHeader }>
			<div className = { style.siteHeader__wrapper }>
					<Link
						to = '/' 
						className = { style.navbarLogo }/>
					<a 
						className = 'hmbg' 
						href = '#'
						onClick = { toggleMenu({open,openMenu}) }>
						<span></span>
						<span></span>
						<span></span>
					</a>
				  <nav className = { style.Navbar }>

				  	<ul className = { style.MenuItems }>
				  		{ menuItems.map(displayMenuItem) }
				  	</ul>

				  	<ul className = { style.MobileMenu }>
				  		{ mobileItems.map(displayMenuItem) }
				  	</ul>

				  	<h6 className = { style.MenuTitle } >Follow us</h6>
				  	<ul className = { style.MobileSocials }>
				  		{ socials.map(displayMenuItem) }
				  	</ul>
				  </nav>
			</div>
	  </header>
	)
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
