import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import style from './components/style/header.module.scss'

/**
 * @namespace Functions
 * @name FunctionsComponent
 */

/**
 * Menu item
 * @typedef {Object} MenuItem
 * @property {string} name - Display name
 * @property {string} url - Url
 * @property {boolean} extern - External link
 * @property {string} className - html class
 * @proterty {MenuItem[]} children - Nested menu
 */

// Mobile / Tablet breakpoint
export const BREAKPOINT = 769;

/** 
 * Update component state
 * @function
 * @name updateState
 * @memberof FunctionsComponent
 * @param {function} setter - state modifier
 * @param {*} currentState - state
 * @return {function} onchange/onclick event handler
 */
export function updateState(setter, currentState) {
	return (evt) => {
		const {name, value} = evt.target

		// Objects
		if ( typeof currentState === 'object' && !Array.isArray(currentState)) {
			let newState = Object.assign({}, currentState);
			newState[name] = value;

			setter(newState);
			return;
		} else { // Array(s), string(s), number(s)
			setter(value);
		}
	}
}

/** 
 * Check is element is in viewport
 * @function isElementInVp
 * @memberof Kpi 
 * @param {HTMLElement} Node - Html element
 * @return {boolean}
 */
export function isElementInVp(Node) {
	if (!Node) return;

	let rect = Node.getBoundingClientRect();
	return (
		rect.top >= 0 || rect.bottom >= 0
	)
}

/**
 * Display content from mark down file
 * @param  {[type]} child [description]
 * @param  {[type]} key   [description]
 * @return {[type]}       [description]
 */
export function pageContent(child, key) {
	const { type, value, children, tagName } = child;

	switch(type){
	  case 'text':
	    return value.split('\n').map((val, i, arr) => {
	    	if ( i === arr.length - 1 ) return val;
	    	return <span key = { i }>{ val }<br/></span>
	    });
	  case 'element':
	    switch(tagName) {
	    	case 'li':
	    		return <li key = { key } >{ children[0].value }</li>
	      case 'em':
	        return <em key = { key } >{ children[0].value }</em>
	      case 'span':
	        return <span key = { key } >{ children[0].value }</span>
	      default:
	      	return null;
	    }
	}
}

/** 
 * Display navbar items
 * @function
 * @name displayMenuItem
 * @memberof FunctionsComponent
 * @param {MenuItem} menuItem - Menu item meta data
 * @param {number} i - index
 * @return {ReactComponent} Menu item
 */
export function displayMenuItem({
	name, 
	url, 
	extern, 
	className, 
	children,
	onMouseLeave,
	onMouseEnter,
	t
}, i) {
	t = t || function(str) { return str; }
	onMouseLeave = onMouseLeave || function(){return;}
	onMouseEnter = onMouseEnter || function(){return;}
	return (
		<li key = { i } onMouseLeave = {onMouseLeave} 
										onMouseEnter = {onMouseEnter}>

			{ url ? 
				<Link to = { url } 
					 activeClassName = { style.linkActive }
					 className = { className ? className : '' }
					 target = { extern ? '_blank' : null }
					 rel = { extern ? 'noopener noreferrer' : null }>
						{ t(name) }
				</Link> : 
				<span className = { className ? className : '' }>
						{ t(name) }
				</span>
			}
			

			{/**
				* Nested elements
				*/}
			{ children && children.length > 0 ?
				<ul className = 'nested-menu animation-done'>
					{children.map(displayMenuItem)}
				</ul> : null }

		</li>
	)
}


/**
 * @interface SmartNav
 * @name SmartNav
 */
/**
 * Add class to navbar on scroll down / scroll up
 * @function
 * @name smartNav
 * @memberof FunctionsComponent
 * @param {Object} options
 * @param {number} options.delta - Scroll threshold 
 * @param {number} options.navbarHeight - Navigation height 
 * @param {HTMLElement} options.header - Navbar Element 
 * @return {SmartNav} Start with init(), kill with destroy() 
 */
export function smartNav(options) {
	const myOptions = {};

	myOptions.lastScrollTop = 0;
	myOptions.delta = options.delta || 5;
	myOptions.navbarHeight = options.navbarHeight || 0;
	myOptions.header = options.header || null;

	return {
		interval: null,
		didScroll: false,
		scrollOpt: options,
		/**
		 * Get document height
		 * @function
		 * @name SmartNav#docHeight
		 * @return {number} Document height
		 */
		docHeight: () => {
			var body = document.body,
					html = document.documentElement;

			var height = Math.max( 
				body.scrollHeight, body.offsetHeight, 
				html.clientHeight, html.scrollHeight, 
				html.offsetHeight 
			);

			return height;
		},
		/**
		 * Watch scroll
		 * @function
		 * @name SmartNav#setInterval
		 */
		setInterval: function() {
			return setInterval(() => {
				if (this.didScroll) {
					this.hasScrolled();
					this.didScroll = false;
				}
			});
		},
		/**
		 * Toggle navbar based on scroll
		 * @function
		 * @name SmartNav#hasScrolled
		 */
		hasScrolled: function() {
			const st = window.scrollY;

			const scrollOpt = this.scrollOpt;

			// did not scroll enough
			if (Math.abs(scrollOpt.lastScrollTop - st) <= scrollOpt.delta) return;
			
			// bellow navbar
			if ( st <= scrollOpt.navbarHeight ) {
				scrollOpt.header.classList.add('on-top');
				scrollOpt.header.classList.add(style.onTop);
			} else {
				scrollOpt.header.classList.remove('on-top');
				scrollOpt.header.classList.remove(style.onTop);
			}

			// If current position > last position AND scrolled past navbar...
			if (st > scrollOpt.lastScrollTop && st > scrollOpt.navbarHeight){
			  // Scroll Down
			  scrollOpt.header.classList.remove('nav-down');
			  scrollOpt.header.classList.remove(style.navDown);
			  scrollOpt.header.classList.add('nav-up');
			  scrollOpt.header.classList.add(style.navUp);
			} else {
			  // Scroll Up
			  // If did not scroll past the document (possible on mac)...
			  if(st + window.innerHeight < this.docHeight()) { 
			    scrollOpt.header.classList.remove('nav-up');
			    scrollOpt.header.classList.remove(style.navUp);
			    scrollOpt.header.classList.add('nav-down');
			    scrollOpt.header.classList.add(style.navDown);
			  }
			}

			this.scrollOpt.lastScrollTop = st;
		},
		/**
		 * Enable `hasScrolled`
		 * @function
		 * @name SmartNav#reset
		 */
		reset: function() {
			this.didScroll = true;
		},
		/**
		 * Initialize module 
		 * @function
		 * @name SmartNav#init
		 */
		init: function() {
			this.interval = this.setInterval();
			window.addEventListener('scroll', this.reset.bind(this))
		},
		/**
		 * Reset/destroy module 
		 * @function
		 * @name SmartNav#destroy
		 */
		destroy: function(){
			window.removeEventListener('scroll', this.reset.bind(this))
			clearInterval(this.interval);
			this.interval = null;
		}
	}
}

/**
 * Component for mobile disapears on big screen
 * @memberof FunctionsComponent
 */
export function MobileComponent({children}) {
  const [mobile, setMobile] = useState(isMobile());
  
  useEffect(() => {
    let handle = ((arg) => {
      return () => {
        if (isMobile() && !arg) setMobile(true);
        if (!isMobile() && arg) setMobile(false);
      }
    })(mobile);
    window.addEventListener('resize', handle);
    return () => {
      window.removeEventListener('resize', handle);
    }
  }, [mobile]);

  if (mobile) return <span>{children}</span>;
  return <span></span>;
}

/**
 * Component for desktop disapears on small screen
 * @memberof FunctionsComponent
 */
export function DesktopComponent({children}) {
  const [mobile, setMobile] = useState(isMobile());

  
  useEffect(() => {
    let handle = ((arg) => {
      return () => {
        if (isMobile() && !arg) setMobile(true);
        if (!isMobile() && arg) setMobile(false);
      }
    })(mobile);
    window.addEventListener('resize', handle);
    return () => {
      window.removeEventListener('resize', handle);
    }
  }, [mobile]);

  if (!mobile) return <span>{children}</span>;
  return <span></span>;
}

/**
 * Is screen smaller than BREAKPOINT
 * @memberof FunctionsComponent
 * @return {Boolean} [description]
 */
export function isMobile() {
  if (typeof window !== `undefined`) {
    return window.innerWidth < BREAKPOINT;
  }
  return false;  
}

/**
 * Get parent matching storyScreen class
 * @param  {[type]} node [description]
 * @return {[type]}      [description]
 */
export function getParentNode(node,className) {
  if (node.parentElement) {
    if (node.parentElement.classList.contains(className))
      return node.parentElement;
    return getParentNode(node.parentElement);
  }

  return node;
}

/**
 * Filter child node from markdown
 * Array filter callback
 * @param  {string} options.type  Node type
 * @param  {string} options.value Node value
 * @return {Boolean}
 */
export function removeNewLine({type, value}) {
  return type === "element";
}